.leadingImage {
    max-width: 1225px;
    width: 100%;
    //Used to override NextJS Image absolute property
    position: relative !important;
}

.pButton {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
        max-width: 80px;
        max-height: 80px;
    }
    z-index: 5;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}

.marginBottom107 {
    margin-bottom: 107px;
}

.categoryText {
    color: #b32767;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(10px, 2vw, 13px);
    line-height: 105%;
    text-decoration: none;
}

.author {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 105%;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    color: #333333;
}

.contentTitle {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    /* or 26px */

    text-align: center;
    letter-spacing: 0.3px;

    /* Gray 1 */

    color: #333333;
}
.contentTitle:hover {
    color: #b32767;
}

@media (min-width: 640px) {
    .pButton {
        position: absolute;
        img {
            max-width: 150px;
            max-height: 150px;
        }
        z-index: 5;
    }
    .author {
        text-align: right !important;
        width: auto !important;
        max-width: none !important;
    }
}

@media (min-width: 768px) {
    .pButton {
        position: absolute;
        img {
            max-width: 50px;
            max-height: 50px;
        }
        z-index: 5;
    }
    .author {
        text-align: right !important;
        width: 100%;
        max-width: 100px;
    }
}

@media (min-width: 1024px) {
    .pButton {
        position: absolute;
        img {
            max-width: 60px;
            max-height: 60px;
        }
        z-index: 5;
    }
    .author {
        text-align: right !important;
        width: 100%;
        max-width: 100px;
    }
}
@media (min-width: 1200px) {
    .pButton {
        position: absolute;
        img {
            max-width: 80px;
            max-height: 80px;
        }
        z-index: 5;
    }
    .author {
        text-align: right !important;
        width: 100%;
        max-width: 100px;
    }
}
