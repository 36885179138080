@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import 'custom';

.newsContainer {
}

@include media-breakpoint-down(sm) {
    .newsContainer {
        padding: 0;
    }
}
