//bootstrap override of the primary color
$primary: #fadce4;
$secondary: #b32767;

//navbar height on different resolutions, used for body padding
$navbar-height-md: 169px;
$navbar-height-sm: 65px;

$border-color: #c4c4c4;

$color-dark: #000000;
$color-shade-gray: #cccccc;
