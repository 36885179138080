@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import 'custom';

.marginBottom107 {
    margin-bottom: 107px;
}

.pButton {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
        max-width: 80px;
        max-height: 80px;
    }
    z-index: 5;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}

.categoryText {
    color: #b32767;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(10px, 2vw, 17px);
    line-height: 105%;
    text-decoration: none;
}

.author {
    font-style: normal;
    font-size: clamp(13px, 2vw, 17px);
    line-height: 105%;
    text-transform: capitalize;
    max-width: 350px;
    color: #333333;
}

.contentTitle {
    max-width: 1230px;
    width: 100%;
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    font-size: clamp(22px, 4vw, 40px);
    letter-spacing: 0.3px;
    color: #333333;
}

.contentTitle:hover {
    color: #b32767;
}

.stickyAd {
    position: sticky;
    position: -webkit-sticky;
    top: $navbar-height-md;
}

@media (min-width: 640px) {
    .pButton {
        position: absolute;
        img {
            max-width: 150px;
            max-height: 150px;
        }
    }
}

@media (min-width: 768px) {
    .pButton {
        position: absolute;
        img {
            max-width: 125px;
            max-height: 125px;
        }
    }
}

@media (min-width: 1024px) {
    .pButton {
        position: absolute;
        img {
            max-width: 150px;
            max-height: 150px;
        }
    }
}
@media (min-width: 1200px) {
    .pButton {
        position: absolute;
        img {
            max-width: 200px;
            max-height: 200px;
        }
    }
}
