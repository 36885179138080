@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';

.footerContainer {
    height: 168px;

    .footerContent {
        border-top: 1px solid #c4c4c4;
    }

    .informationLinks {
        max-width: 720px;
        width: 100%;
    }

    .womanBgLogo {
        width: 100%;
        max-width: 362px;
    }

    .privacyPolicy {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 24px;
    }
}

//TODO ADD BREAKPOINTS FOR OTHER SIZES
@include media-breakpoint-down(sm) {
    .footerContainer {
        height: 100%;

        .socialMediaLogos {
            max-width: 32px;
            max-height: 32px;
        }

        .womanBgLogo {
            max-width: 184px;
        }
    }
}

@include media-breakpoint-up(md) {
    .footerContainer {
        height: 168px;
    }
}
