.searchContainer {
    background-color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.searchInput {
    border-right: none;
    &:focus {
        color: #212529;
        background-color: #fff;
        border-color: #ced4da;
        border-right: none;
        box-shadow: none;
    }
}

.searchActionIcon {
    background-color: white;
    padding-top: 1px;
    padding-bottom: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #ced4da;
    min-width: 50px;
    border-left: none;
}

.closeSearchInput {
    background-color: white;
    padding-top: 1px;
    padding-bottom: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #fff;
    min-width: 30px;
    padding-right: 5px;
    border-left: none;
}
