.line {
    background-color: #c4c4c4;
}

.title {
    font-size: clamp(25px, 4vw, 40px);
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0.3px;
    color: #333333;
    text-align: left;
}
